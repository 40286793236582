<template>
  <div class="course_detail_list_container">
    <my-tabs :tabs="tabs" v-model:activeTab="activeTab"></my-tabs>
    <div class="separator"></div>
    <course-section-card
      v-for="section in currentList"
      :key="section.courseDetailId"
      :info="section"
      :canot-watch="canotWacth"
    ></course-section-card>
  </div>
</template>
<script>
/* eslint-disable */
import MyTabs from "./MyTabs.vue";
import CourseSectionCard from "./CourseSectionCard.vue";
import { connectState } from "../utils/wyUtil";

export default {
  name: "courseList",
  components: { CourseSectionCard, MyTabs },
  props: {
    listData: {
      type: Object,
      default: () => ({})
    },
    canotWacth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...connectState("course", ["chapterTypes", "chaptersSort"])
  },
  watch: {
    chapterTypes(val) {
      let obj = {};
      Array.isArray(val) &&
        val.forEach(item => {
          obj = { ...obj, [item.key]: item.value };
        });
      this.courseTypes = obj;
    },
    listData(val) {
      let sortObj = this.chaptersSort || {};
      let list =
        Object.keys(val).map(key => {
          let sort = isNaN(sortObj[key]) || sortObj[key] * 1 > 999 ? 999 : sortObj[key] * 1;
          return { label: this.courseTypes[key], value: key, sort };
        }) || [];
      this.tabs = list.sort((a, b) => a.sort * 1 - b.sort * 1);
      this.activeTab = (this.tabs[0] || {}).value || "";
    },
    activeTab(val) {
      this.currentList = this.listData[val] || [];
    }
  },
  data() {
    return {
      courseTypes: {
        1: "听力",
        2: "阅读",
        3: "写作",
        0: "口语"
      },
      // courseType (integer, optional): 章类型/课程类型(0：口语 1：听力 2：阅读 3：写作 4：语法) ,
      tabs: [],
      activeTab: "",
      currentList: []
    };
  }
};
</script>
<style lang="scss" scoped>
.course_detail_list_container {
  width: 950px;
  min-width: 500px;
  background: #ffffff;
  box-shadow: 0px 2px 16px 0px rgba(202, 205, 214, 0.47);
  border-radius: 6px;
  padding: 15px 15px 50px;

  .separator {
    width: 100%;
    height: 3px;
    background: #f8f8f8;
    margin: 15px 0;
  }
}
</style>
