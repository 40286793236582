<template>
  <div class="course_detail_page_container">
    <back-btn class="back_btn"></back-btn>
    <div class="cards_container">
      <course-detail-card
        :info="courseDetail"
        :show-group-btn="!groupFlag"
        :show-pub-btn="showPubBtn"
        :show-receive-btn="showReceiveBtn"
        @joinGroup="handleOpenDialog"
        @openWepubDialog="openWepubDialog"
        @receiveMaterial="receiveMaterial"
        @viewMaterial="viewMaterial"
      ></course-detail-card>
      <course-list :list-data="sectionData" :canot-watch="canotWacth"></course-list>
    </div>
    <el-dialog title="群信息" v-model="dialogVisible" :center="true" width="562px">
      <p class="join_code">
        入群口令：<span>{{ groupsData.word }}</span>
      </p>
      <group-card
        v-for="(item, index) in groupsData.group"
        :key="item.number"
        :sort="index"
        :info="item"
      ></group-card>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <followed-pub-account ref="pubAccount" type="class" @nextHandler="()=>{}"></followed-pub-account>

  <el-dialog title="领取资料" center v-model="receiveMaterialDialogVisible" width="527px" height="283px">
    <div class="receiveCon">
      <div class="title">链接地址</div>
      <div class="hStack">
        <a :href="materialInfo.materialUrl"
          >{{materialInfo.materialUrl}}</a
        >
        <div class="copy" @click="handleCopy(materialInfo.materialUrl)">复制</div>
      </div>
      <div class="title">提取密码</div>
      <div class="hStack">
        <div class="extractPassword">{{materialInfo.withdrawPassword}}</div>
        <div class="copy" @click="handleCopy(materialInfo.withdrawPassword)">复制</div>
      </div>
    </div>
  </el-dialog>
  </div>
</template>
<script>
import CourseDetailCard from "@/components/CourseDetailCard.vue";
import CourseList from "@/components/CourseList.vue";
import BackBtn from "@/components/BackBtn.vue";
import { connectState } from "@/utils/wyUtil";
import GroupCard from "./GroupCard.vue";
import FollowedPubAccount from "@/components/FollowedPubAccount/index.vue"

export default {
  components: {
    GroupCard,
    CourseList,
    CourseDetailCard,
    BackBtn,
    FollowedPubAccount
  },
  data() {
    return {
      chargeCourseId: this.$route.params.id,
      groupFlag: this.$route.query.group === "n",
      dialogVisible: false,
      canotWacth: this.$route.query.mode === "0",
      showPubBtn: false,
      receiveMaterialDialogVisible: false,
      materialInfo: {},
    };
  },
  computed: {
    ...connectState("course", ["courseDetail", "sectionData", "groupsData", "chaptersSort", "materialData"])
  },
  watch: {
    chaptersSort(val) {
      if (val) {
        this.getSections();
      }
    },
    materialData(val) {
      if (val.content) {
        this.materialInfo = val.content;
        this.openReceiveMaterialDialog();
      }
      if (val.receive) {
        this.getCourseDetail();
      }
    },
  },
  async created() {
    this.querySort();
    this.getChaptersType();
    this.getCourseDetail();
    this.getCourseGroups();
    const hasFollowed = await this.$store.dispatch("oral/checkFollowStatus");
    this.showPubBtn = !hasFollowed;
  },
  methods: {
    querySort() {
      this.$store.dispatch("course/getChaptersSort", { chargeCourseId: this.chargeCourseId });
    },
    getChaptersType() {
      this.$store.dispatch("course/fetchChapterTypes");
    },
    getCourseDetail() {
      this.$store.dispatch("course/fetchCourseDetail", { chargeCourseId: this.chargeCourseId });
    },
    getSections() {
      // courseType (integer, optional): 章类型/课程类型(0：口语 1：听力 2：阅读 3：写作 4：语法) ,
      this.$store.dispatch("course/fetchSections", { chargeCourseId: this.chargeCourseId });
    },
    getCourseGroups() {
      this.$store.dispatch("course/fetchGroupList", { chargeCourseId: this.chargeCourseId });
    },
    
    handleOpenDialog() {
      this.dialogVisible = true;
    },
    openWepubDialog(){
      this.$refs.pubAccount.handleOpen()
    },
    receiveMaterial() {
      this.$store.dispatch("course/receiveMaterial", { chargeCourseId: this.chargeCourseId });
    },
    viewMaterial() {
      this.$store.dispatch("course/viewMaterial", { chargeCourseId: this.chargeCourseId });
    },
    openReceiveMaterialDialog() {
      this.receiveMaterialDialogVisible = true
    },
    handleCopy(val) {
      const input = document.createElement('input');
      input.setAttribute('readonly', 'readonly');
      input.setAttribute('type', 'text');
      input.setAttribute('value', val);
      document.body.appendChild(input);
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        console.log('复制成功');
        this.$message({ message: '复制成功', type: 'success' });
      }
      document.body.removeChild(input);
    },
  }
};
</script>
<style lang="scss" scoped>
.course_detail_page_container {
  .back_btn {
    margin-bottom: 20px;
  }
  .cards_container {
    width: 965px;
    height: auto;
  }
  .join_code {
    height: 20px;
    font-size: 18px;
    font-weight: 400;
    color: #4e596f;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .receiveCon {
    display: flex;
    flex-direction: column;
    .title {
      font-size: 14px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #131b33;
      line-height: 19px;
      margin-bottom: 12px;
    }
    .hStack {
      display: flex;
      align-items: center;
      margin-bottom: 28px;
    }
    .extractPassword {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #8e95a3;
      line-height: 19px;
      padding-right: 16px;
    }
    .copy {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 20px;
      background: #0097e8;
      border-radius: 6px;
      padding: 4px 12px;
    }
    a {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #0097e8;
      line-height: 19px;
      margin-right: 16px;
    }
  }
}
</style>
