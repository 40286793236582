<template>
  <div class="card_detail_container">
    <img :src="info.coverUrl" alt="image" class="course_cover" />
    <div class="text_container">
      <p class="title">{{ info.chargeCourseName }}</p>
      <p v-if="info.rightsExpiredDate" class="time">课程有效期：至 {{ info.rightsExpiredDate }}</p>
      <p class="time">上课时间：{{ info.chargeCourseBeginDate }}～{{ info.chargeCourseEndDate }}</p>
      <p class="time teacher">授课教师：{{ info.teacherName }}</p>
    </div>
    <div class="button">
      <div v-if="info.rightsIsExpired">
        <img class="out_date_img" src="@/assets/img/icon_ygq@3x.png" />
      </div>
      <common-button v-else-if="showGroupBtn" type="plain_primary" @click="handleClick">
        立即入群
      </common-button>
    </div>
    <common-button type="plain_primary" @click="handleFollowed" v-if="showPubBtn">
      关注公众号
    </common-button>
    <common-button type='plain_primary' :class="info.receivedMaterial ? 'gray' : ''" @click="handleReceive" v-if="info.configMaterial">
      {{info.receivedMaterial ? '已领取' : '领取资料'}}
    </common-button>
  </div>
</template>
<script>
import CommonButton from "./CommonButton.vue";

export default {
  name: "courseDetailCard",
  components: { CommonButton },
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    showGroupBtn: {
      type: Boolean,
      default: true
    },
    showPubBtn: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick() {
      this.$emit("joinGroup");
    },
    handleFollowed() {
      this.$emit("openWepubDialog");
    },
    handleReceive() {
      if (this.info.receivedMaterial) {
        this.$emit("viewMaterial");
      } else {
        this.$emit("receiveMaterial");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.card_detail_container {
  width: 950px;
  background: #ffffff;
  border-radius: 6px;
  padding: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .course_cover {
    flex: none;
    width: 162px;
    height: 121px;
    border-radius: 6px;
    border: 1px solid #eee;
    margin-right: 15px;
  }
  .text_container {
    flex: 1;
    height: 100%;
    .title {
      height: 40px;
      font-size: 14px;
      font-weight: 600;
      color: #131b33;
      line-height: 20px;
      overflow: hidden;
      margin-bottom: 13px;
    }
    .time {
      font-size: 12px;
      font-weight: 400;
      color: #363d51;
      line-height: 17px;
      margin-bottom: 10px;
    }
    .teacher {
      margin-bottom: 0;
    }
    .out_date_img {
      width: 154px;
      height: 122px;
    }
  }
  .button {
    // width: 150px;
    flex: none;
  }
  .gray{
    background: #C8C8C8;
    border: 2px solid #C8C8C8;
    color:#FFF;
  }
}
</style>
