<template>
  <div class="common_button_wrapper" :class="type||null" @click="handleClick">
    <slot class="text"></slot>
  </div>
</template>
<script>
export default {
  name: 'commonButton',
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    handleClick: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {};
  },

};
</script>
<style lang="scss" scoped>
  .common_button_wrapper{
    min-width:86px;
    width:auto;
    height:45px;
    line-height: 45px;
    text-align: center;
    padding:0 13px;
    border-radius: 9px;

    font-size: 20px;
    font-weight: 600;
    margin:0 20px;
    cursor: pointer;

  }
  .primary{
    background: #3377FF;
    border: 2px solid #3377FF;
    color:#FFF;
  }
  .plain{
    border: 2px solid #3377FF;
    color:#3377FF;
  }
  .plain_primary{
    background: rgba(51, 119, 255, 0.1);
    color:#3377FF;
  }
  .warning{

  }
  .disable{
    background: #C8C8C8;
    border: 2px solid #C8C8C8;
    color:#FFF;
    cursor: not-allowed;
  }

</style>
