<template>
  <div class="group_card_container">
    <p class="title_row">
      <span class="line"></span>
      <span class="title">QQ群{{sort+1}}</span></p>
   <div class="card_list_container">
     <p>
       <span>名称：</span>
       <span class="value_content">{{info.name}}</span>
     </p>
     <p>
       <span>号码：</span>
       <span class="value_content">
         {{info.number}}
         <span class="copy_btn" @click="handleCopy(info.number)">复制</span></span>
     </p>
   </div>
  </div>
</template>
<script>
export default {
  name: 'groupCard',
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    sort: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    handleCopy(val) {
      const input = document.createElement('input');
      input.setAttribute('readonly', 'readonly');
      input.setAttribute('type', 'text');
      input.setAttribute('value', val);
      document.body.appendChild(input);
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        console.log('复制成功');
        this.$message({ message: '复制成功', type: 'success' });
      }
      document.body.removeChild(input);
    },
  },

};
</script>
<style lang="scss" scoped>
  .group_card_container{
    width: 516px;
    height: 120px;
    background: #F8F8F8;
    border-radius: 9px;
    margin-bottom:15px;
    .title_row{
      height:46px;
      line-height: 46px;
      border-bottom:1px solid #E5E5E5;

      .line{
        display:inline-block;
        width: 4px;
        height: 20px;
        background: #3377FF;
        border-radius: 0px 44px 44px 0px;
        vertical-align: middle;
        margin-right:10px;
      }
      .title{
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #333B50;
        line-height: 20px;

      }
    }
    .card_list_container{
      padding:0 15px;
      p{
        height:37px;
        line-height: 37px;
        font-size: 14px;
        font-weight: 500;
        color: #72777E;
        .value_content{
          float:right;
          cursor: pointer;

          .copy_btn{
            color:#3377FF;
            margin-left: 5px;
            &:hover{
              opacity:0.7;
            }
          }
        }

      }
    }

  }

</style>
