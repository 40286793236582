<template>
  <el-dialog
    title="关注微信公众号"
    v-model="dialogVisible"
    width="562px"
    :center="true"
    :before-close="handleClose"
  >
    <div class="pubDialog">
      <img class="wxicon" :src="require('@/assets/img/wx_pay.png')" alt="" />
      <p class="title">{{ textMap[type] }}</p>
      <div class="codeWrapper">
        <img
          class="qrcode"
          v-if="wxPubAccountQrcode"
          :src="wxPubAccountQrcode"
        />
      </div>
      <div class="btnWrapper">
        <div class="btn cancelBtn" @click="handleCancel">下次再说</div>
        <div class="btn" @click="handleCheck">关注好啦</div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { connectState } from "@/utils/wyUtil";
export default {
  props: {
    type: {
      type: String,
      default: "class",
    },
  },
  data() {
    return {
      dialogVisible: false,
      textMap: {
        writing: "及时查看批改进度，请务必关注公众号哟~",
        oral: "及时查看约课上课状态，请务必关注公众号哟~",
        class: "微信扫码关注雅思哥课堂，享上课提醒服务~",
      },
      openLocation: ''
    };
  },
  computed: {
    ...connectState("oral", ["wxPubAccountQrcode"]),
    ...connectState("login", ["userInfo"]),
  },
  watch: {
    dialogVisible: function (val) {
      if (val && !this.wxPubAccountQrcode) {
        this.$store.dispatch("oral/createPubAccount", {
          url: "/pages/wxPages/publicAccount/index",
          scene: `userId=${this.userInfo.userId}`,
        });
      }
    },
  },
  methods: {
    handleCancel() {
      let obj = new Object();
      obj.isOpen = "0"; // 要保存的变量
      obj.time = 60 * 60 * 24; // 过期时间
      obj.date = new Date().getTime() / 1000; // 保存变量时的那个时间点，这里以时间戳为例
      obj.openLocation = this.openLocation;
      if (this.openLocation === 'submit') {
        window.localStorage.setItem("isSubmitFirstOpen", true);
      } else if (this.openLocation === 'practice') {
        window.localStorage.setItem("isPracticeFirstOpen", true);
      } else if (this.openLocation === 'oral') {
        window.localStorage.setItem("isOralFirstOpen", true)
      } else if (this.openLocation === 'stage') {
        window.localStorage.setItem("isOralStageFirstOpen", true)
      } else if (this.openLocation === 'oralPlan') {
        window.localStorage.setItem("isOralPlanOpen", true)
      }
      var objString = JSON.stringify(obj); // 由于 localStorage 只能保存字符串内容，所以这里要先把对象转换成 JSON 字符串
      window.localStorage.setItem("isOpen", objString);
      this.dialogVisible = false;
      this.$emit("nextHandler");
    },
    handleOpen(type) {
      this.openLocation = type
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    async handleCheck() {
      const hasFollowed = await this.$store.dispatch("oral/checkFollowStatus");
      if (!hasFollowed) {
        this.$message.error("您还没有关注公众号哦～");
        return false;
      }
      this.dialogVisible = false;
      this.$emit("nextHandler");
    },
  },
};
</script>
<style lang="scss" scoped>
.pubDialog {
  .el-dialog__title {
    font-size: 20px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #131b33;
    margin-bottom: 50px;
  }

  .wxicon {
    display: block;
    margin: auto;
    width: 44px;
  }

  .title {
    font-size: 22px;
    font-family: MicrosoftYaHei;
    color: #333643;
    line-height: 29px;
    margin-top: 10px;
    text-align: center;
  }

  .codeWrapper {
    width: 240px;
    height: 240px;
    margin: 60px auto 47px;
    background: #f5f6fa;
    border-radius: 50%;
  }

  .qrcode {
    width: 240px;
    height: 240px;
    display: block;
  }
  .btnWrapper {
    display: flex;
    justify-content: center;
    .btn {
      width: 118px;
      background: #3377ff;
      border-radius: 13px;
      line-height: 42px;
      font-size: 14px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      // margin: auto;
      cursor: pointer;
    }
    .cancelBtn {
      background: rgba(51, 119, 255, 0.1);
      color: #3377ff;
      margin-right: 20px;
    }
  }
}
</style>