<template>
  <div class="course_section_item_container">
    <i class="section_icon" :class="wrapIcon()"></i>
    <div class="text_wrapper">
      <p class="title">
        <span class="text">{{ info.sectionName }}</span>
        <span class="live_tip" v-if="[2, 3].includes(info.lessonStatus)"
          >直播中</span
        >
      </p>
      <p class="time">
        <span>{{ wrapTime() }}</span>
        <span class="duration">{{ info.courseHours }}分钟</span>
        <span :class="info.isWatch ? 'success' : 'warning'">{{
          info.isWatch ? "已学习" : "未学习"
        }}</span>
      </p>
    </div>
    <div class="button">
      <common-button
        :type="canotWacth || disabled ? 'disable' : wrapStatus().type"
        :handle-click="handlePushPage"
      >
        {{ wrapStatus().text }}
      </common-button>
    </div>
  </div>
</template>
<script>
import CommonButton from "./CommonButton.vue";
import { connectState } from "@/utils/wyUtil";

export default {
  name: "courseSectionCard",
  components: { CommonButton },
  computed: {
    ...connectState("course", ["courseDetail"])
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    canotWacth: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    const { rightsIsExpired } = this.courseDetail
    if (rightsIsExpired) {
      this.disabled = true
    } else {
      this.disabled = false
    }
  }, 
  data() {
    return {
      // 直播文案：
      courseStatus: {
        // lessonStatus (integer, optional): 课状态 1:未开课,2:直播中,3:已结束,4:录制中,5:回放中 ,
        1: { type: "disable", text: "即将开课", value: 1 },
        2: { type: "primary", text: "观看直播", value: 2 },
        3: { type: "primary", text: "观看直播", value: 3 }, // 已结束状态不展示，进入直播间后提示已结束
        4: { type: "disable", text: "正在录制", value: 4 },
        5: { type: "plain", text: "观看回放", value: 5 },
        6: { type: "plain", text: "观看录播", value: 6 },
      },
      disabled: false
    };
  },
  methods: {
    wrapTime() {
      const { courseBeginTime, courseEndTime, playType } = this.info;
      //  2021-01-18 00:00:00
      const start = courseBeginTime ? courseBeginTime.substring(0, 16) : "";
      const end = courseEndTime ? courseEndTime.substring(11, 16) : "";
      return `${start}${!playType ? "-" : ""}${!playType ? end : ""}`;
    },
    wrapStatus() {
      let { lessonStatus } = this.info;
      const { playType, playbackType } = this.info;
      // lessonStatus (integer, optional): 课状态 1:未开课,2:直播中,3:已结束,4:录制中,5:回放中 ,
      // playType (integer, optional): 播放方式(0：直播 1：录播) ,
      // playbackType (integer, optional): 回放类型 (Ps：当play_type 是录播的时候，字段才有效 0：视频 ,1：录制件) ,
      // playbackContent
      if (lessonStatus === 5) {
        if (playType === 0 || playbackType === 1) {
          lessonStatus = 5;
        } else {
          lessonStatus = 6;
        }
      }
      return this.courseStatus[lessonStatus];
    },
    wrapIcon() {
      const { playType } = this.info;
      return !playType ? "live_icon" : "replay_icon";
    },
    handlePushPage() {
      if (this.canotWacth) {
        this.$message.error("您还没有该课程的权限，请先选课哦");
        return false;
      }
      if (this.disabled) {
        return false;
      }
      const {
        lessonStatus,
        playbackContent,
        courseId,
        courseDetailId,
        playType,
        playbackType,
        sectionName,
      } = this.info;
      console.log("----courseId:", courseDetailId);
      // courseId:欢拓的课程ID
      if ([2, 3].includes(lessonStatus)) {
        // 观看直播
        if (!courseId) {
          this.$message.error("欢拓courseId不能为null");
          return false;
        }
        if (playType == 1 && playbackType * 1 === 1) {
          window.open(
            `/replayLive?courseId=${courseId}&courseDetailId=${courseDetailId}&sectionName=${encodeURIComponent(
              sectionName
            )}`
          );
        } else {
          window.open(`/livePlay/${courseId}/${courseDetailId}`);
        }
      }
      if (lessonStatus === 5) {
        // 观看回放
        if (playType === 0 || playbackType === 1) {
          // 直播的回放
          window.open(
            `/replayLive?courseId=${courseId}&courseDetailId=${courseDetailId}&sectionName=${encodeURIComponent(
              sectionName
            )}`
          );
        } else {
          window.open(
            `/videoReplay?videoUrl=${encodeURIComponent(
              playbackContent
            )}&name=${encodeURIComponent(sectionName)}&id=${courseDetailId}`
          );
        }
      }
      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
.course_section_item_container {
  width: 100%;
  height: 95px;
  background: #f8f8f8;
  border-radius: 9px;
  margin-bottom: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;

  .section_icon {
    flex: none;
    width: 34px;
    height: 34px;
    background: #8590a6;
    margin-right: 25px;
  }
  .live_icon {
    background: url(../assets/img/course/live_icon@2x.png) 0 0 no-repeat;
    background-size: 100% 100%;
  }
  .replay_icon {
    background: url(../assets/img/course/replay_icon@2x.png) 0 0 no-repeat;
    background-size: 100% 100%;
  }

  .text_wrapper {
    max-width: 715px;
    overflow: hidden;
    flex: 1;

    .title {
      height: 28px;
      font-size: 20px;
      font-weight: bold;
      color: #131b33;
      line-height: 28px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 15px;
      .text {
        display: inline-block;
        vertical-align: middle;
      }
      .live_tip {
        display: inline-block;
        height: 23px;
        background: #ff2342;
        border-radius: 12px;
        font-size: 13px;
        font-weight: bold;
        color: #ffffff;
        line-height: 23px;
        padding: 0 13px;
        vertical-align: middle;
        margin-left: 10px;
      }
    }
    .time {
      height: 22px;
      font-size: 16px;
      font-weight: 400;
      color: #131b33;
      line-height: 22px;

      .duration {
        margin-left: 15px;
        margin-right: 15px;
      }
      .warning {
        color: #ff9955;
      }
      .success {
        color: #2fc83c;
      }
    }
  }

  .button {
    flex: none;
    margin-right: -20px;
  }
}
</style>
